<template>
  <div style="padding-top: 2rem">

    <div v-if="!loadScreen">
      <div class="info-container p-0 mx-auto">
        <div class="mb-3 text-end" v-if="userData.roleId == 2">
          <button type="button" class="btn btn-inline bg-pri text-light"
                  @click="unpublishClass()" v-if="published">
            Unpublish Class
          </button>
          <button type="button" class="btn btn-inline bg-pri text-light"
                  @click="publishClass()" v-else>
            Publish Class
          </button>
        </div>
        <div class="card instructor-card mb-5 card-shadow">
          <div class="card-body">
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <h3 class="card-title">{{ roster.instructor.name }}</h3>
                <h5 class="card-subtitle mb-2 text-muted">Main Instructor</h5>
                <p class="card-text">Email: {{ roster.instructor.email }}</p>
              </div>
              <div class="list-group-item" v-for="(staff, idx) in roster.staff" :key="idx">
                <h4 class="card-title">{{ staff.name }}</h4>
                <h6 class="card-subtitle mb-2 text-muted">Instructor</h6>
                <p class="card-text">Email: {{ staff.email }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card card-shadow mb-5">
          <div class="card-body">
            <h3 class="card-title">Students</h3>
            <table id="studentTable" class="display table"></table>
          </div>
        </div>
        <div class="m-0 justify-content-end" v-if="userData.roleId == 2">
          <button type="button" class="btn btn-inline bg-dark text-light me-2" @click="editClass">
            Edit class
          </button>
          <button type="button" class="btn btn-inline btn-danger"
                  @click="deleteClass">Delete class
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import $ from 'jquery';
import Swal from 'sweetalert2';

export default {
  name: 'CourseInfo',
  components: {},
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      roster: {
        instructor: {
          name: null,
          email: null,
          username: null,
        },
        staff: [],
      },
      table: null,
      published() {
        return this.$store.state.courseInfo.published;
      },
    };
  },
  methods: {
    editClass() {
      this.$router.push({ path: `/courses/create?cId=${this.$route.params.cid}` });
    },
    deleteClass() {
      Swal.fire({
        title: 'Are you sure you want to delete this course?',
        text: 'This action cannot be undone.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.get(`/api/course/${this.$route.params.cid}/delete`)
            .then(() => {
              this.$router.replace({ path: '/courses/' });
            }).catch((err) => {
              Swal.fire(
                'Oops!',
                'The assessment could not be deleted.',
                'error',
              );
              console.log(err);
            });
        }
      });
    },
    publishClass() {
      Swal.fire({
        title: 'Are you sure you want to publish this course?',
        text: 'You will not be able to add or edit course content or modules',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, publish it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post('/api/course/publish', {
              courseId: this.$route.params.cid,
              role: this.userData.roleId,
            })
            .then(() => this.$store
              .dispatch('loadCourseInfo', { id: this.$route.params.cid })
              .then(() => this.getCourseInfo())
              .catch((error) => {
                if (error.response.status === 403) {
                  this.$router.replace({ path: '/forbidden/' });
                }
              }))
            .catch((err) => console.log(err));
        }
      });
    },
    unpublishClass() {
      Swal.fire({
        title: 'Are you sure you want to unpublish this course?',
        text: 'Students will no longer be able to access the course content or modules, and all student data will be erased if you republish',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unpublish it!',
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post('/api/course/unpublish', {
              courseId: this.$route.params.cid,
              role: this.userData.roleId,
            })
            .then(() => {
              this.$store
                .dispatch('loadCourseInfo', { id: this.$route.params.cid })
                .then(() => this.getCourseInfo())
                .catch((error) => {
                  if (error.response.status === 403) {
                    this.$router.replace({ path: '/forbidden/' });
                  }
                });
            })
            .catch((err) => console.log(err));
        }
      });
    },
    getCourseInfo() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get(`/api/course/${this.$route.params.cid}/roster`)
        .then((response) => {
          this.roster = response.data;
          this.published = response.data.published;
          this.$store.commit('setLoadScreen', { status: false });
          setTimeout(() => {
            this.table = $('#studentTable').DataTable({
              autoWidth: false,
              info: false,
              searching: false,
              paging: false,
              data: this.roster.students,
              columns: [
                { title: 'Name', data: 'name' },
                { title: 'Username', data: 'username' },
                { title: 'Email Address', data: 'email' },
              ],
            });
          }, 0);
        });
    },
  },
  beforeMount() {
    this.getCourseInfo();
  },
};
</script>
<style scoped>
.info-container {
  max-width: 1200px;
}
</style>
